import { useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import {
  ExclamationOctagonIcon,
  Loader,
  Select,
  SelectOption,
  Typography,
} from '@la/ds-ui-components';
import { getSiteId, getUserId, useAppSelector } from '@la/services';
import { MainContent, MainContentCenter, Stepper } from '@la/shared-components';
import { WizardContent } from '@la/shared-components/src/tournaments/registration/Wizard/Wizard.styles';
import {
  RegisteredRegistrationType,
  RegistrationDecodedParams,
  RegistrationType,
} from '@la/types';
import { getLAHostnameParts } from '@la/utilities';
import { Alert } from 'components/Alert/Alert';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import { useGetSelectRegistrationQuery } from 'redux/services/registrationApi';
import { DiscardChangesModal } from 'domains/RosterManagement/RosterRollover/DiscardChangesModal/DiscardChangesModal';
import { API_ERROR_MESSAGE } from '../Registration/Registration';
import { WizardHeader } from '../Registration/Wizard/components/WizardHeader/WizardHeader';
import { RegistrationTypeItem } from './RegistrationTypeItem/RegistrationTypeItem';
import styles from './SelectRegistrationType.module.scss';
import { SelectRegistrationFields } from './SelectRegistrationType.types';
import {
  additionalInfoFields,
  registrationTypesAdditionalInfoTitle,
  registrationTypesSubtitles,
  registrationTypesTitles,
} from './SelectRegistrationType.utils';

const DEFAULT_FIELD_VALUES: SelectRegistrationFields = {
  selectedDivision: '',
  selectedType: RegistrationType.Team,
  selectedStaffRole: '',
  selectedTeam: '',
};
const ADDITIONAL_INFO_SECTION_ID = 'additional-info-section';
// Enums are weird to iterate through, especially narrowing down to Enums dictionary where
// the key and definition are the same. eg: typeof enum === enum[string].
const registrationTypesValues: RegisteredRegistrationType[] = [
  RegistrationType.Team,
  RegistrationType.Player,
  RegistrationType.PlayerFreeAgent,
  RegistrationType.Staff,
  RegistrationType.StaffFreeAgent,
];

const baseSchema = yup.object({
  selectedType: yup.string().required(),
  selectedDivision: yup.string().when('selectedType', {
    is: (role: string) => {
      return role !== RegistrationType.Team;
    },
    then: () => yup.string().required('Please select a program.'),
    otherwise: () => yup.string(),
  }),
  selectedTeam: yup.string().when('selectedType', {
    is: (role: RegisteredRegistrationType) => {
      const required = additionalInfoFields[role]?.includes('selectedTeam');
      return required;
    },
    then: () => yup.string().required('Please select a team.'),
    otherwise: () => yup.string(),
  }),
  selectedStaffRole: yup.string().when('selectedType', {
    is: RegistrationType.Staff,
    then: () => yup.string().required('Please select a role.'),
    otherwise: () => yup.string(),
  }),
});

const getRegistrantData = (
  selectedType: RegisteredRegistrationType | null,
  state: {
    selectedDivision: string;
    siteId: string | null;
    selectedTeam?: string;
    selectedStaffRole?: string;
  }
) => {
  const { selectedDivision, siteId, selectedTeam, selectedStaffRole } = state;

  const baseRegistrationData = {
    prid: selectedDivision,
    site: siteId,
  };

  switch (selectedType) {
    case RegistrationType.Player: {
      const data = {
        ...baseRegistrationData,
        role: 'player',
        type: RegistrationType.Player,
        team: selectedTeam,
      };
      return data;
    }
    case RegistrationType.PlayerFreeAgent: {
      const data = {
        ...baseRegistrationData,
        role: 'player',
        type: RegistrationType.PlayerFreeAgent,
      };
      return data;
    }
    case RegistrationType.Staff: {
      const data = {
        ...baseRegistrationData,
        role: selectedStaffRole,
        type: RegistrationType.Staff,
        team: selectedTeam,
      };
      return data;
    }
    case RegistrationType.StaffFreeAgent: {
      const data = {
        ...baseRegistrationData,
        role: selectedStaffRole ? selectedStaffRole : undefined,
        type: RegistrationType.StaffFreeAgent,
      };

      return data;
    }
  }
};

export const SelectRegistrationType = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState<RegisteredRegistrationType>(
    RegistrationType.Team
  );
  const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);

  const { tournamentId } = useParams();
  const userId = useAppSelector(getUserId);
  const siteId = useAppSelector(getSiteId);

  if (!tournamentId) {
    throw new Error('The tournament ID is not defined');
  }

  const { subdomain: siteDomain } = getLAHostnameParts();

  const methods = useForm<SelectRegistrationFields>({
    defaultValues: DEFAULT_FIELD_VALUES,
    resolver: yupResolver(baseSchema),
  });

  const {
    watch,
    formState: { errors },
    setValue,
    reset,
    trigger,
    getFieldState,
  } = methods;

  const selectedTeam = watch('selectedTeam');
  const selectedStaffRole = watch('selectedStaffRole');
  const selectedDivision = watch('selectedDivision');

  const handleRoleChange = (type: RegisteredRegistrationType | null) => {
    if (type) {
      reset();
      setSelectedType(type);
      setValue('selectedType', type);
    }
    if (type && type !== RegistrationType.Team) {
      setTimeout(() => {
        window.scrollTo({ behavior: 'smooth', top: 1200 });
      }, 40);
    }
  };

  const {
    data,
    isError: tournamentError,
    isLoading,
  } = useGetSelectRegistrationQuery({
    siteDomain,
    tournamentId,
    userId: userId.toString(),
  });

  if (isLoading) {
    return (
      <MainContentCenter>
        <Loader description="We are gathering registration data..." loading />
      </MainContentCenter>
    );
  }

  if (tournamentError) {
    return (
      <MainContent>
        <WizardContent>
          <ErrorCard message={API_ERROR_MESSAGE} />
        </WizardContent>
      </MainContent>
    );
  }

  if (!data) {
    throw new Error('No data found for the tournament.');
  }

  const {
    divisionsOptions,
    staffRolesOptions,
    teamsOptions,
    divisionsFees,
    tournament,
    priceRanges,
  } = data;

  const navigateToMemberRegistration = (data: RegistrationDecodedParams) => {
    const encodedData = window.btoa(JSON.stringify(data));
    const path = `/app/registration`;
    navigate(`${path}?selectRegistrationData=${encodedData}`);
  };

  const handleNextClick = () => {
    if (selectedType === RegistrationType.Team) {
      navigate(`/app/tournaments/${tournamentId}/registration`);
      return;
    }

    const registrantData = getRegistrantData(selectedType, {
      selectedDivision,
      siteId,
      selectedTeam,
      selectedStaffRole,
    });

    if (!registrantData) {
      console.error('Invalid selected type');
      return;
    }

    navigateToMemberRegistration(registrantData);
  };

  const onSubmit = () => {
    trigger().then(() => {
      if (!selectedType) {
        return;
      }
      const hasErrors = additionalInfoFields[selectedType]
        .map((key) => {
          return getFieldState(key as keyof SelectRegistrationFields).invalid;
        })
        .some((invalid) => invalid);
      if (!hasErrors) {
        handleNextClick();
      }
    });
  };

  const getRegistrationWindow = () => {
    let divisionsStartDate: DateTime | undefined;
    let divisionsEndDate: DateTime | null = null;

    tournament.divisions.forEach((division) => {
      if (
        divisionsStartDate === undefined ||
        DateTime.fromISO(division.startDate) < divisionsStartDate
      ) {
        divisionsStartDate = DateTime.fromISO(division.startDate);
      }

      if (
        division.endDate !== null &&
        division.endDate !== undefined &&
        (divisionsEndDate === null ||
          DateTime.fromISO(division.endDate) > divisionsEndDate)
      ) {
        divisionsEndDate = DateTime.fromISO(division.endDate);
      }
    });

    return { divisionsStartDate, divisionsEndDate };
  };

  const navigateToTournamentPage = () => {
    navigate(`/app/tournaments/${tournament.id}`);
  };

  const { divisionsStartDate, divisionsEndDate } = getRegistrationWindow();

  const getRegistrationTypeDisabledState = (
    registrationType: RegisteredRegistrationType
  ) => {
    if (registrationType === RegistrationType.Team) {
      return false;
    }

    if (
      !tournament.divisions
        .map((division) => division.id)
        .some((id) => divisionsFees[id][registrationType].enabled)
    ) {
      return true;
    }

    if (divisionsOptions[registrationType].length === 0) {
      return true;
    }

    if (
      teamsOptions.length === 0 &&
      additionalInfoFields[registrationType].includes('selectedTeam')
    ) {
      return true;
    }
    return false;
  };

  return (
    <FormProvider {...methods}>
      <MainContent>
        <WizardHeader
          endDate={divisionsEndDate ?? undefined}
          location={
            !tournament.hideLocationDetails
              ? tournament.location?.name
              : undefined
          }
          name={tournament.name}
          startDate={
            divisionsStartDate || DateTime.fromISO(tournament.startDate)
          }
        />
        <Stepper
          currentStep={'Step'}
          error={false}
          form={{}}
          handleNextClick={onSubmit}
          numberOfTotalSteps={2}
          onBackClick={() => {}}
          showDrawer={true}
          stepNumber={1}
          showSteps={false}
          showBackButton={false}
          type="button"
          isMC={false}
          onCancelButtonClick={() => {
            setShowDiscardChangesModal(true);
          }}
        >
          <form className={styles.selectRegistrationContainer}>
            <Typography variant="headline" size="medium" weight="bold">
              Select a registration type
            </Typography>
            {errors.selectedType ? (
              <Alert
                message="Please select a registration type before continuing."
                icon={
                  <ExclamationOctagonIcon
                    size="xl"
                    variant="bold"
                    fill="var(--red-500)"
                  />
                }
              />
            ) : null}
            <div className={styles.selectionSection}>
              {registrationTypesValues.map((registrationType) => (
                <RegistrationTypeItem
                  key={registrationType}
                  type={registrationType}
                  selectedType={selectedType}
                  setSelectedType={handleRoleChange}
                  title={registrationTypesTitles[registrationType]}
                  subtitle={registrationTypesSubtitles[registrationType]}
                  price={priceRanges[registrationType]}
                  disabled={getRegistrationTypeDisabledState(registrationType)}
                />
              ))}
            </div>
            <div id={ADDITIONAL_INFO_SECTION_ID}>
              <RoleAdditionalInfo
                selectedType={selectedType}
                selectedTeam={selectedTeam}
                selectedDivision={selectedDivision}
                selectedStaffRole={selectedStaffRole}
                teamsOptions={teamsOptions}
                divisionsOptions={
                  selectedType ? divisionsOptions[selectedType] : []
                }
                staffRolesOptions={staffRolesOptions}
              />
            </div>
          </form>
        </Stepper>
        {showDiscardChangesModal ? (
          <DiscardChangesModal
            onDiscardChangesClick={navigateToTournamentPage}
            onOpenChange={setShowDiscardChangesModal}
            open={showDiscardChangesModal}
          />
        ) : null}
      </MainContent>
    </FormProvider>
  );
};

const RoleAdditionalInfo = ({
  selectedType,
  selectedDivision,
  divisionsOptions,
  teamsOptions,
  staffRolesOptions,
}: {
  selectedType: RegisteredRegistrationType | null;
  selectedDivision: string;
  divisionsOptions: SelectOption[];
  teamsOptions: SelectOption[];
  selectedTeam: string;
  staffRolesOptions: SelectOption[];
  selectedStaffRole: string;
}) => {
  const { setValue, control, clearErrors } =
    useFormContext<SelectRegistrationFields>();

  if (!selectedType || selectedType === RegistrationType.Team) {
    return null;
  }

  const requiredFields = additionalInfoFields[selectedType];

  return (
    <div className={styles.additionalInfoSection}>
      <Typography variant="headline" size="small" weight="bold">
        {registrationTypesAdditionalInfoTitle[selectedType]}
      </Typography>
      <div className={styles.additionalInfoContent}>
        {requiredFields.includes('selectedDivision') ? (
          <Controller
            control={control}
            name="selectedDivision"
            render={({ field, fieldState }) => {
              return (
                <div className={styles.additionalInfoDivisions}>
                  <Select
                    {...field}
                    id="selectedDivision"
                    label="Program"
                    options={divisionsOptions}
                    onChange={(divisionId) => {
                      setValue('selectedDivision', divisionId);
                      clearErrors('selectedDivision');
                    }}
                    placeholder="Select a program"
                    required
                    width="100%"
                    hasError={!!fieldState.error}
                    errorMessage={fieldState.error?.message}
                  />
                </div>
              );
            }}
          />
        ) : null}
        {requiredFields.includes('selectedTeam') ? (
          <Controller
            control={control}
            name="selectedTeam"
            render={({ field, fieldState }) => {
              return (
                <div className={styles.additionalInfoTeams}>
                  <Select
                    {...field}
                    id="selectedTeam"
                    label="Team"
                    placeholder="Select a team"
                    options={teamsOptions}
                    onChange={(teamId) => {
                      setValue('selectedTeam', teamId);
                      clearErrors('selectedTeam');
                    }}
                    required
                    disabled={!selectedDivision}
                    width="100%"
                    hasError={!!fieldState.error}
                    errorMessage={fieldState.error?.message}
                  />
                </div>
              );
            }}
          />
        ) : null}
        {requiredFields.includes('selectedStaffRole') ? (
          <Controller
            control={control}
            name="selectedStaffRole"
            render={({ field, fieldState }) => {
              return (
                <div className={styles.additionalInfoRoles}>
                  <Select
                    {...field}
                    id="selectedStaffRole"
                    placeholder="Select a role"
                    label="Your role"
                    options={staffRolesOptions}
                    onChange={(roleId) => {
                      setValue('selectedStaffRole', roleId);
                      clearErrors('selectedStaffRole');
                    }}
                    required={selectedType === RegistrationType.Staff}
                    optional={selectedType === RegistrationType.StaffFreeAgent}
                    hasError={!!fieldState.error}
                    errorMessage={fieldState.error?.message}
                  />
                </div>
              );
            }}
          />
        ) : null}
      </div>
    </div>
  );
};
