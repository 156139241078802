import { useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FileInput,
  Form,
  InputErrorMessage,
  InputLabel,
  Select,
  TextArea,
  TextInput,
  InputSizeVariant,
} from '@la/ds-ui-components';
import { createValidationSchema } from '@la/shared-components';
import { FormField } from '@la/types';
import { useRegistration } from 'lib/context/RegistrationContext/RegistrationContext';
import { chunkArray } from 'lib/utils/utilities';
import { CheckboxGroupFormComponent } from 'domains/RosterManagement/RegistrantProfileForm';
import * as S from '../RegistrationInfoCard.styles';

export type CustomFieldsFormFields = {
  [key: string]: FormField[];
};

export type CustomFieldsFormProps = {
  /**
   * Number of columns to render fields in. Default is 2.
   */
  columns?: 1 | 2;
  /**
   * Custom form fields to include.
   */
  formFields: FormField[];
  /**
   * Gap between fields when `columns` is 1 when on tablet
   * landscape or larger.
   */
  gap?: number;
  /**
   * Id of the form and the field array name.
   */
  id: string;
  /**
   * Called when form is submitted.
   * @param values Object with property `id` that is an array
   * of the fields.
   */
  onSubmit: (values: CustomFieldsFormFields) => void;
};

function CustomFieldsForm({
  columns = 2,
  formFields,
  gap,
  id,
  onSubmit,
}: CustomFieldsFormProps) {
  const validation = useMemo(() => createValidationSchema(id), [id]);

  const methods = useForm<CustomFieldsFormFields>({
    defaultValues: {
      [id]: formFields,
    },
    resolver: yupResolver(validation),
  });

  const {
    formState: { isSubmitting, errors },
    handleSubmit,
  } = methods;

  const { setHasErrors } = useRegistration();

  useEffect(() => {
    setHasErrors(Object.keys(errors).length > 0);
  }, [errors, setHasErrors]);

  return (
    <FormProvider {...methods}>
      <Form
        id={id}
        noValidate
        onSubmit={handleSubmit((values) => {
          if (!isSubmitting) {
            onSubmit(values);
          }
        })}
      >
        <CustomFields columns={columns} gap={gap} name={id} />
      </Form>
    </FormProvider>
  );
}

type CustomFieldsProps = Pick<CustomFieldsFormProps, 'columns' | 'gap'> & {
  name: string;
  inputSize?: InputSizeVariant;
};

/**
 * Renders a set of custom fields with two inputs per row.
 * @param name The name of the form field that represents the array of
 * fields. Should match the name used in the containing useForm() component.
 */
function CustomFields({
  columns = 2,
  gap,
  name,
  inputSize = 'medium',
}: CustomFieldsProps) {
  const {
    control,
    formState: { errors, submitCount },
    register,
    setValue,
    watch,
  } = useFormContext<CustomFieldsFormFields>();

  const { fields, update } = useFieldArray({
    control,
    name,
  });

  const [customFileErrors, setCustomFileErrors] = useState<
    Record<string, string>
  >({});

  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);

  const fieldGroups = chunkArray(fields, columns);

  return (
    <>
      {fieldGroups.map((fieldGroup, fieldGroupIndex) => (
        <S.InputGroupContainer $gap={gap} key={`${name}-${fieldGroupIndex}`}>
          {fieldGroup.map((field, fieldIndex) => {
            // Multiply `fieldGroupIndex` by `columns` so that the resulting index corresponds
            // to the index of the field from the `useFieldArray`'s `fields` array
            const index = fieldGroupIndex * columns + fieldIndex;
            const {
              id,
              name: fieldName,
              propertyDefinitionId,
              type,
              usageLevel,
            } = field;
            const error = errors?.[name]?.[index]?.value?.message;
            const required = usageLevel === 'REQUIRED';
            const hasFileError = uploadedFiles.find((fileId) => fileId === id)
              ? false
              : Boolean(!!error ?? customFileErrors[id]);
            switch (type) {
              case 'TEXT_BOX':
                return (
                  <S.TextInputContainer
                    $columnSpan={columns === 1 ? 2 : 1}
                    key={propertyDefinitionId}
                  >
                    <TextInput
                      {...register(`${name}.${index}.value`)}
                      errorMessage={error?.toString()}
                      hasError={!!error}
                      key={id}
                      id={id}
                      label={fieldName}
                      required={required}
                      size={inputSize}
                      value={watch(`${name}.${index}.value`) as any}
                    />
                  </S.TextInputContainer>
                );
              case 'NUMERIC':
                const {
                  min: _min,
                  max: _max,
                  ...numericRegisterProps
                } = register(`${name}.${index}.value`);

                return (
                  <S.TextInputContainer
                    $columnSpan={columns === 1 ? 2 : 1}
                    key={propertyDefinitionId}
                  >
                    <TextInput
                      {...numericRegisterProps}
                      errorMessage={error?.toString()}
                      hasError={!!error}
                      key={id}
                      id={id}
                      label={fieldName}
                      onChange={(e) => {
                        setValue(
                          `${name}.${index}.value`,
                          e.target.value.toString()
                        );
                      }}
                      required={required}
                      size={inputSize}
                      value={watch(`${name}.${index}.value`) as any}
                      type="number"
                      forceLeftAlignment={true}
                    />
                  </S.TextInputContainer>
                );
              case 'PICK_LIST':
                const { items } = field;
                const currentItemId = watch(`${name}.${index}.value`);
                const currentItem = items.find(
                  (item) => item.itemId === currentItemId
                );
                const currentValue = currentItem?.value || currentItem?.name;

                return (
                  <S.InputContainer
                    $columnSpan={columns === 1 ? 2 : 1}
                    key={propertyDefinitionId}
                  >
                    <Select
                      {...register(`${name}.${index}.value`)}
                      errorMessage={error?.toString()}
                      hasError={!!error}
                      key={id}
                      id={id}
                      label={fieldName}
                      onChange={(value) => {
                        const itemId = items.find(
                          (item) => item.value === value || item.name === value
                        )?.itemId;

                        setValue(`${name}.${index}.value`, itemId, {
                          shouldValidate: submitCount > 0,
                        });
                      }}
                      options={items.map((item) => ({
                        label: item.name,
                        value: item.value ? item.value : item.name,
                      }))}
                      placeholder={`Select an option`}
                      required={required}
                      size={inputSize}
                      value={currentValue}
                    />
                  </S.InputContainer>
                );
              case 'TEXT_AREA':
                return (
                  <S.InputContainer
                    $columnSpan={columns === 1 ? 2 : 1}
                    key={propertyDefinitionId}
                  >
                    <TextArea
                      {...register(`${name}.${index}.value`)}
                      errorMessage={error?.toString()}
                      hasError={!!error}
                      key={id}
                      id={id}
                      label={fieldName}
                      required={required}
                      size={inputSize}
                      value={watch(`${name}.${index}.value`) as any}
                    />
                  </S.InputContainer>
                );
              case 'MULTIPLE_CHECKBOXES':
                const { ref: _ref, ...checkboxesRegisterProps } = register(
                  `${name}.${index}.value`
                );

                const options = field.items.map((item) => ({
                  label: item.name,
                  value: item.itemId.toString(),
                  selected: false,
                }));

                return (
                  <S.InputContainer
                    $columnSpan={columns === 1 ? 2 : 1}
                    key={propertyDefinitionId}
                  >
                    <CheckboxGroupFormComponent
                      {...checkboxesRegisterProps}
                      checkboxGroupData={{
                        hasError: !!error,
                        isRequired: required,
                        name: fieldName,
                        label: fieldName,
                        value: field.value?.join(',') ?? '',
                        options,
                        type: 'MULTIPLE_CHECKBOXES',
                      }}
                      key={id}
                      onChangeValue={(value) => {
                        // Need to use `update` instead of `setValue` here because
                        // update performs an unmount and remount and we need it
                        // in order to appropriately update the value.
                        update(index, {
                          ...field,
                          value: value
                            .split(',')
                            .map((v) => parseInt(v))
                            .filter((v) => !!v),
                        });
                      }}
                      value={(watch(`${name}.${index}.value`) as any)?.join(
                        ','
                      )}
                    />
                    {error ? (
                      <InputErrorMessage>{error.toString()}</InputErrorMessage>
                    ) : null}
                  </S.InputContainer>
                );
              case 'FILE_UPLOAD':
                return (
                  <S.InputContainer
                    $columnSpan={columns === 1 ? 2 : 1}
                    key={propertyDefinitionId}
                  >
                    <InputLabel
                      htmlFor={id}
                      inputState={hasFileError ? 'error' : 'default'}
                      required={required}
                    >
                      {fieldName}
                    </InputLabel>
                    <FileInput
                      accept={['.jpg', '.png', '.jpeg', '.pdf']}
                      alignment="center"
                      errorMessage={customFileErrors[id] ?? error?.toString()}
                      hasError={hasFileError}
                      key={id}
                      id={id}
                      infoMessage="Max upload size is 10mb. Supported file types are .jpg, .png, and .pdf."
                      multiple={false}
                      onDismiss={() => {
                        setValue(`${name}.${index}.value`, undefined);
                        setUploadedFiles((prev) =>
                          prev.filter((fileId) => fileId !== id)
                        );
                      }}
                      onError={({ message }) =>
                        setCustomFileErrors((prev) => ({
                          ...prev,
                          [id]: message,
                        }))
                      }
                      initialValue={fields
                        .filter(
                          (field) =>
                            field.type === 'FILE_UPLOAD' &&
                            field.value &&
                            field.id === id
                        )
                        .map((field) => {
                          return (field.value as any).name;
                        })}
                      onUpload={(file) => {
                        if (file) {
                          setValue(`${name}.${index}.value`, file);
                          setUploadedFiles((prev) => prev.concat(id));
                        }
                      }}
                    />
                  </S.InputContainer>
                );
              default:
                return null;
            }
          })}
        </S.InputGroupContainer>
      ))}
    </>
  );
}

export { CustomFieldsForm, CustomFields };
